#register-image {
    background-image: url("../../public/bgImg/register-bg.jpg");
    background-position: center;
    height: 100%;
    background-size: cover;
}

#to-login {
    display: flex;
    justify-content: end;
    text-decoration: none;
}

#register-body-container {
    width: 90%;
    margin: 2rem auto;
}

#register-main-container {
    border: var(--main-theme-color) solid 3px;
    border-radius: 0.5rem;
    background-color: antiquewhite;
    width: 39%;
    margin: 5rem auto;
    padding: 2rem;
}

#register-title {
    text-align: center;
    color: var(--main-theme-color);
}

#register-form {
    margin: 0 auto;
    width: 100%;
}