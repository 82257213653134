#orders-body-container {
    width: 100%;
    background-color: var(--creamed-bg-color);
}

.absolute-center-right {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(50%, -50%);
}

@media (min-width: 576px) {
    .absolute-center-right {
        right: 1rem;
    }
}

@media (min-width: 992px) {
    .absolute-center-right {
        right: 2rem;
    }
}

.order-details-product-img {
    width: 50px;
    height: min-content;
}