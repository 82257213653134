#payment-intermedia-body {
    background-color: var(--creamed-bg-color) !important;
    width: 100%;
    height: 100vh;

}

#redirection-countdown {
    font-style: normal;
    color: var(--second-theme-color);
}