#top-header,
#products-navigation-header {
    min-height: 4rem;
}

/* logo */
#header-logo {
    text-decoration: none;
    color: var(--main-theme-color);
    font-family: var(--logo-font-style);
    font-weight: 700;
}

#header-logo-j {
    color: var(--logo-highlight-color);
}

/* search bar */
#header-search-cube {
    width: 80%;
}

#header-search-icon {
    z-index: 5;
    font-size: 1.2rem;
}

.header-search-bar {
    height: 2.3rem;
    color: var(--grey-theme-color);
    font-size: 1.1rem;
    outline: none;
}

.fs-result-title {
    font-size: 0.8rem;
}

#search-dropdown {
    position: absolute;
    margin-top: 1.5rem;
    z-index: 5;
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: 0.3rem;
    animation: appearFromTop 0.3s ease forwards;
    cursor: default;
}

#search-dropdown::before {
    content: "";
    position: absolute;
    top: -0.8rem;
    left: 30%;
    transform: translate(-50%);
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
    border-bottom: 0.9rem solid white;
}

/* function cube */
#header-function-cube {
    min-height: 2.5rem;
    font-size: 1.5rem;
    color: var(--grey-theme-color);
}

.header-function-dropdown-list {
    display: block;
    text-decoration: none;
    color: var(--main-theme-color);
}

.header-function-dropdown-list:hover {
    background-color: #fff3cd;
}

#header-user-icon,
#header-cart-icon {
    color: black;
}

#items-quantity-badge {
    width: 24px;
    height: 24px;
    font-size: 0.85rem;
}

#header-sign-in-text {
    margin: 0 0 0 1rem;
    font-size: 1.1rem;
    color: var(--main-theme-color);
    font-weight: 400;
}

/* 
prompt dropdown 
*/
.prompt-dropdown {
    position: absolute;
    margin-top: 3rem;
    z-index: 5;
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: 0.3rem;
    animation: appearFromTop 0.3s ease forwards;
    cursor: default;
}

.prompt-dropdown::before {
    content: "";
    position: absolute;
    top: -0.8rem;
    left: 50%;
    transform: translate(-50%);
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
    border-bottom: 0.9rem solid white;
}

/* ------------------------------------ */
/* products navigation */
@media (max-width: 991px) {
    #header-search-cube {
        width: 100%;
    }
}

.hover-cursor:hover {
    cursor: pointer;
}

.products-navigation-text,
.products-navigation-icons {
    font-weight: 300;
}

.products-navigation-icons {
    font-size: 1.4rem;
    margin-right: 0.3rem;
}

.products-navigation-text {
    margin: 0;
    font-size: 1rem;
}

#navigation-all-wines:hover {
    text-decoration: underline;
}

/* -- -- product nav dropdown */
@keyframes appearFromTop {
    from {
        transform: translateY(-10%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
/* 
grapes dropdown
*/
.header-grape-dropdown {
    position: absolute;
    display: grid;
    grid-template: repeat(4, 1fr) / repeat(3, 1fr);
    gap: 0.5rem;
    align-items: center;
    min-height: max-content;
    min-width: max-content;
    top: 48px;
    z-index: 5;
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: 0.3rem;
    animation: appearFromTop 0.3s ease forwards;
}

.header-grape-dropdown::before {
    content: "";
    position: absolute;
    top: -0.8rem;
    left: 9%;
    transform: translate(-50%);
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
    border-bottom: 0.9rem solid white;
}
/* 
region dropdown
*/
.header-region-dropdown {
    position: absolute;
    min-height: max-content;
    min-width: max-content;
    top: 48px;
    z-index: 5;
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: 0.3rem;
    animation: appearFromTop 0.3s ease forwards;
}

.header-region-dropdown::before {
    content: "";
    position: absolute;
    top: -0.8rem;
    left: 9%;
    transform: translate(-50%);
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
    border-bottom: 0.9rem solid white;
}
/* 
offer dropdown
*/
.header-offer-dropdown {
    position: absolute;
    display: grid;
    grid-template: repeat(4, 1fr) / repeat(1, 1fr);
    gap: 0.5rem;
    align-items: center;
    min-width: 20rem;
    min-height: max-content;
    top: 48px;
    z-index: 5;
    background-color: white;
    box-shadow: var(--box-shadow);
    border-radius: 0.3rem;
    animation: appearFromTop 0.3s ease forwards;
}

.header-offer-dropdown::before {
    content: "";
    position: absolute;
    top: -0.8rem;
    left: 9%;
    transform: translate(-50%);
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
    border-bottom: 0.9rem solid white;
}

.nav-dropdown-element-strong {
    text-decoration: none;
    color: black;
    font-weight: 600;
    font-size: 1rem;
    padding: auto;
}

.nav-dropdown-element-normal {
    text-decoration: none;
    color: black;
    font-weight: 400;
    font-size: 1rem;
    padding: auto;
}

.nav-dropdown-element-strong:hover,
.nav-dropdown-element-normal:hover {
    text-decoration: underline;
}

@media (max-width: 992px) {
    .responsive-nav-element {
        font-size: 14px;
    }

    .responsive-menu-height {
        height: 250px !important;
    }
}

/* responsive nav menu */
#responsive-nav-menu {
    position: absolute;
    width: 100%;
    height: max-content;
    background-color: white;
}

.menu-option-name a.active {
    background-color: #d1e7dd !important;
    color: #0a3622 !important;
    border-color: #d1e7dd !important;
    font-weight: 600;
}

#responsive-tab-container div.active {
    display: flex;
}

