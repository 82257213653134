#login-modal .modal-body {
    position: relative;
}

.modal-close-cube {
    position: absolute;
    right: 0;
    top: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: end;
}
.modal-close-cube:hover {
    cursor: pointer;
}

.modal-width {
    width: 65% !important;
    margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .modal-width {
        width: 70% !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .modal-width {
        width: 74% !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .modal-width {
        width: 70% !important;
    }
}

@media (max-width: 575px) {
    .modal-width {
        width: 90% !important;
    }
}

.modal-row {
    height: 100vh !important;
}

#login-image {
    background-image: url("../../public/bgImg/login-bg.jpg");
    background-position: center;
    height: 100%;
    background-size: cover;
}

/* logo */
.modal-logo {
    font-size: 1.9rem;
    color: rgb(0, 78, 0);
    font-family: 'Reem Kufi Fun', sans-serif;
    font-weight: 700;
}

.modal-logo-j {
    color: rgb(255, 119, 0);
    font-family: 'Quicksand', sans-serif;
}

.modal-vertical-rules {
    font-weight: 200 !important;
}

.modal-red-text {
    color: #a52a2a;
}

#to-register {
    display: flex;
    justify-content: end;
    text-decoration: none;
}

@media (max-width: 320px) {
    .modal-logo-j,
    .modal-logo-cellar,
    .modal-vertical-rules {
        display: none;
    }
}