.product-star-rating {
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    width: min-content;
    margin: 0;
}

.times-font-family {
    font-family: 'Times New Roman', Times, serif;
    font-size: 2.8rem;
}