.product-img,
.product-img-hovered {
    position: relative;
    max-height: 18rem;
    min-height: 15rem;
    transition: transform 0.2s ease-in-out;
}

.product-img {
    top: -13%;
}

.product-img-hovered {
    top: -15%;
    transform: scale(1.02) translate(0, -0.2rem);
}

@media (min-width: 768px) {
    .product-img,
    .product-img-hovered {
        max-height: 21rem;
        min-height: 18rem;
    }
}