/* 1st container */
#product-details-1st-container {
    background-color: var(--creamed-bg-color);
}

/* -- -- rows in wine info */
.wine-info-row.details-wine-info-row {
    margin-top: 1.5rem;
}

.font-family-cambria {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

#details-quantity-info {
    height: 3rem;
}

#details-add-to-cart-button {
    background-color: #198754;
    color: whitesmoke;
    border: #198754 solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.3rem;
}

#details-add-to-cart-button:active {
    background-color: var(--second-theme-color);
    transform: var(--button-action);
    border: var(--second-theme-color) solid 0.1rem;
}

/* -- etd */
#etd-row {
    display: flex;
    justify-content: space-evenly;
    font-size: 0.8rem;
}

@media (min-width: 1200px) {
    #delivery-icon {
        font-size: 1.5rem !important;
    }
}

#etd-row p{
    padding: 0;
    margin: 0 0.8rem;
}