@media (max-width: 424px) {
    .declaration-text {
        font-size: 14px !important;
    }
}

/* recommended product part*/
.home-product-container {
    border: var(--grey-theme-color) solid 0.05rem !important;
    margin: 0.3rem !important;
    text-decoration: none !important;
    position: relative !important;
    border-radius: 2rem !important;
}

.home-product-container:hover {
    box-shadow: var(--box-shadow) !important;
}

/* fix height of each product cube so that they are the same height before 4 in a row */
@media (min-width: 576px) and (max-width: 767px) {
    .product-vintage-cube-height {
        min-height: 50px !important;
    }
}

@media (min-width: 992px) {
    #top-rated-img {
        width: 150px !important;
    }
}