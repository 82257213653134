.assignated-font-family {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

@media (min-width: 992px) {
    .assignated-font-family {
        font-size: 1.1rem !important;
    }
}

.fi {
    margin-right: 0.5rem;
    border-radius: 50%;
    font-size: 1.5rem;
}