#empty-cart-container,
#cart-body-container {
    background-color: var(--creamed-bg-color);
    width: 100%;
}

#empty-cart-container {
    height: 100vh;
}

.empty-cart-img {
    width: 80px;
}

.item-box {
    height: fit-content;
    margin: 0.5rem 0;
    box-shadow: var(--box-shadow);
}

.item-box:hover {
    box-shadow: var(--box-shadow-active-green);
}

@media (max-width: 425px) {
    .cart-product-img {
        width: 65% !important;
    }
}

@media (min-width: 1200px) {
    .cart-product-img {
        width: 40% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .cart-fs-smaller {
        font-size: 0.8rem !important;
    }
}

.cart-delete-btn-position {
    position: absolute;
    top: 5%;
    right: 15px;
}

.checkout-button {
    border: whitesmoke solid 2px;
    background-color: #198754;
    color: whitesmoke;
    cursor: pointer;
    user-select: none;
}

.checkout-button:active {
    background-color: var(--second-theme-color) !important;
    transform: var(--button-action) !important;
    border-color: var(--second-theme-color) !important;
}

.spinner-size {
    width: 22px;
    height: 22px;
    font-size: 11px;
}