#checkout-body-container {
    background-color: var(--creamed-bg-color);
    width: 100%;
    height: 100%;
}

.checkout-item-price-quantity-cube {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.payment-testing-info-tablist .nav-item button {
    color: black;
    font-weight: 550;
}

.payment-testing-number {
    cursor: pointer;
}