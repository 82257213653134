#profile-page-body-container {
    width: 100%;
    height: 100%;
}

#profile-page-user-info-box {
    width: 40%;
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.min-height {
    height: min-content;
}

/* wishlist */
#user-wishlist-section .tab-content {
    height: 70vh !important;
    display: flex !important;
    flex-direction: column;
    overflow: scroll;
}

.profile-img-resize {
    width: 35%;
}

.input-width-resize {
    width: 90%;
}

@media (min-width: 576px) {
    .wishlist-img-resize {
        width: 30% !important;
    }
    .profile-img-resize {
        width: 25%;
    }
    .input-width-resize {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .wishlist-img-resize {
        width: 25% !important;
    }
    .profile-img-resize {
        width: 20%;
    }
}

@media (min-width: 992px) {
    .wishlist-img-resize {
        width: 35% !important;
    }
    .profile-img-resize {
        width: 30%;
    }
}

@media (min-width: 1200px) {
    .wishlist-img-resize {
        width: 30% !important;
    }
}

@media (min-width: 1400px) {
    .wishlist-img-resize {
        width: 25% !important;
    }
}

/* profile edition */
#edition-bg-img {
    background-image: url("../../public/bgImg/edition-bg.JPG");
    height: 100%;
    background-size: cover;
    background-position: center;
}