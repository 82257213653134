#root {
  font-size: 16px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  --main-font-style: 'Quicksand', sans-serif;
  --logo-font-style: 'Reem Kufi Fun', sans-serif;
  --logo-highlight-color: rgb(255, 119, 0);
  --main-theme-color: rgb(0, 78, 0);
  --second-theme-color: rgb(245, 150, 33);
  --grey-theme-color: rgb(161, 161, 161);
  --box-shadow: 0.2rem 0.2rem 0.7rem darkgray;
  --box-shadow-active-green: 0.1rem 0.1rem 0.5rem rgb(8, 30, 8);
  --box-shadow-active-orange: 0.1rem 0.1rem 0.5rem rgb(218, 121, 2);
  --button-action: translateY(0.18rem);
  --white-font-color: whitesmoke;
  --light-bg-color: #fafafa;
  --creamed-bg-color: #f7f3f0;
}

* {
  font-family: var(--main-font-style);
}

#root-container {
  background-color: var(--light-bg-color);
}

#error-handler-body {
  background-color: rgb(253, 252, 244);
  height: 100vh;
  width: 100%;
}

.xxs-fs {
  font-size: 12px;
}

.xs-fs {
  font-size: 0.9rem;
}

.clickable-icon:hover {
  cursor: pointer;
}

.underline-hovered:hover {
  text-decoration: underline !important;
}

.vh-100 {
  height: 100vh;
}

@media (max-width: 450px) {
  #root {
    font-size: 12px;
  }
}

@media (min-width: 451px) and (max-width: 575px) {
  #root {
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  .width-narrow-25 {
    width: 25% !important;
  }

  .intermedia-top-text-fs {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .width-narrow-20 {
    width: 20% !important;
  }
}

@media (min-width: 992px) {
  .intermedia-top-text-fs {
    font-size: 1.8rem !important;
  }

  .lg-screen-fs-1point3rem {
    font-size: 1.3rem !important;
  }

  .lg-screen-fs-1point2rem {
    font-size: 1.2rem !important;
  }

}

@media (min-width: 1200px) {
  .width-narrow-15 {
    width: 15% !important;
  }
}