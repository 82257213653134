#products-body-container {
    width: 100%;
}
 
/* headline */
#products-headline-box-3 {
    display: flex;
    flex-wrap: wrap;
}

#products-headline-box-3 .filter-grape-country-active {
    margin-right: 0.5rem;
    height: 2.5rem;
}

#products-headline-box-3 .filter-grape-country-active span {
    font-size: 0.8rem;
    margin: 0.85rem 0.5rem auto 0;
}

#products-main-container {
    margin: 2rem 2rem;
}

/* filters */
.dimensioned-badge {
    width: 1.3rem;
    height: 1.3rem;
}

#products-filters-container {
    display: flex;
    flex-direction: column;
}

.products-filters-box {
    border: none;
    height: fit-content;
    margin-bottom: 2.9rem;
}

.products-filters-box-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.products-filters-box-title h3 {
    margin: 0;
    font-size: 1.3rem;
}

.products-filters-box-title h6 {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 400;
}

 /* -- -- -- active filter option */
.filter-wine-type-active,
.filter-grape-country-active {
    background-color: brown !important; 
    color: white !important;
}

/* -- -- wine type */
.filter-wine-type, 
.filter-wine-type-active {
    border-radius: 5rem;
    height: 2rem;
    width: 7.5rem;
    border: brown solid 0.07rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-wine-type:hover, 
.filter-wine-type-active:hover {
    cursor: pointer;
} 

.filter-wine-type p {
    font-size: 0.9rem;
    font-weight: 450;
    color: brown;
}

/* -- -- price range */
#products-filters-box-price {
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding: 0 0.25rem;
    margin: 1.5rem 0 1rem 0;
}

#products-filters-box-price h6 {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
}

/* slider */
.filter-slider {
    width: 100%;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .filter-slider-thumb {
    height: 0.9rem;
    width: 0.9rem;
    text-align: center;
    border: white solid 0.2rem;
    background-color: crimson;
    color: #fff;
    border-radius: 50%;
    cursor: grab;
  }
  
  .filter-slider-track {
    height: 0.25rem;
    border-radius: 3px;
    display: flex;
    background-color: #ddd;
  }
  
  .filter-slider .filter-slider-track-1 {
    background-color: crimson; 
  }  

/* -- -- rating filter */
#products-filters-box-rating {
    display: flex;
    flex-direction: column;
    height: 8rem;
    justify-content: space-evenly;
    margin-top: 1rem;
}

/* -- -- -- custom radio */
/* Hide the default radio button */
.filter-rating-radio input[type="radio"] {
    display: none;
}
  
/* Create a custom radio button */
.filter-rating-radio .filter-rating-radio-button {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    border: 0.1rem solid #ddd;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
  
/* Indicate when the radio button is selected */
.filter-rating-radio input[type="radio"]:checked + .filter-rating-radio-button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.95rem;
    height: 0.95rem;
    background-color: crimson;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.filter-rating-radio .filter-rating-radio-stars-holder {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-left: 0.5rem;
}

.filter-rating-radio p {
    margin: 0 0 0 0.5rem;
    font-size: 0.8rem;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.filter-rating-radio p span {
    font-weight: 700;
    font-size: 0.9rem;
    margin-right: 0.5rem;
}

/* -- grape filter */
.products-filters-search-box {
    border-bottom: #ddd solid 0.1rem;
    margin: 1.5rem 0 1rem 0;
}

.filter-search-icon {
    display: inline-block;
    vertical-align: middle;
    color: lightgrey;
    font-size: 1.5rem;
}

.filter-search-line {
    border: none;
    display: inline-block;
    vertical-align: middle;
}

.filter-search-line:focus {
    outline: none;
    border: none;
}

.products-filters-box-grape-country {
    height: min-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.filter-grape-country,
.filter-grape-country-active {
    height: 1.8rem;
    width: fit-content;
    padding: 0 0.5rem;
    border: brown solid 0.08rem;
    border-radius: 2rem;
    color: brown;
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    margin: 0.3rem 0.2rem;
}

.filter-grape-country:hover,
.filter-grape-country-active:hover {
    cursor: pointer;
}

/* -- country filter */
.filter-grape-country span,
.filter-grape-country-active span {
    font-size: 1rem;
}

/* product */
.product-container {
    border: var(--grey-theme-color) solid 0.05rem;
    height: fit-content;
    text-decoration: none;
    position: relative;
    border-radius: 2rem;
}

.product-container:hover {
    box-shadow: var(--box-shadow);
}